import _ from 'lodash'
import storageMap from '../../storage/storageMap'
import storage from '../../storage'
import digitalItems from './digitalItems.json'
import { toFixed1IfDecimal } from '../../utils'
import nodeDefinitionTypeMap from '../../../src/components/scoring_tree/helper/nodeDefinitionTypeMap'
import rolesInfoData from './rolesInfo.json'

/**
 * Determine if a node is eligible (enabled) for the calculation
 *
 * @param {Object} node
 * @param {String} scoreSetType
 * @returns
 */
// eslint-disable-next-line no-unused-vars
export function isNodeEligibleForCal(node, nodeDefinition, scoreSetType = null) {
  return node.is_enabled
}

function itemIsDigital(nodeDefinitions, itemId) {
  const node = nodeDefinitions[itemId]
  let b = false
  if (node) {
    const { en } = node.name
    digitalItems.map(item => {
      if (en.includes(item)) {
        b = true
      }
      return item
    })
  }
  return b
}

// Recursion from perimeters to items and find out max scores of every level inside a specific perimeter
function findMaxs(levelIndex, treeMap, perimeterId, parentId, maxScores, defKeys, nodeDefinitions, productNodes) {
  if (levelIndex > treeMap.length - 1) {
    return
  }

  for (let i = 0; i < defKeys.length; i += 1) {
    const def = nodeDefinitions[defKeys[i]]
    if (def.type === treeMap[levelIndex] && def.parent_id === parentId) {
      if (!maxScores[perimeterId][treeMap[levelIndex]]) {
        maxScores[perimeterId][treeMap[levelIndex]] = 0
      }
      if (maxScores[perimeterId][treeMap[levelIndex]] < productNodes[def.id].score) {
        maxScores[perimeterId][treeMap[levelIndex]] = productNodes[def.id].score
      }
      findMaxs(levelIndex + 1, treeMap, perimeterId, def.id, maxScores, defKeys, nodeDefinitions, productNodes)
    }
  }
}

function setNormalizedScores(defKeys, nodeDefinitions, productNodes) {
  const configMaxScore = JSON.parse(global.env.config.max_product_score)
  for (let i = 0; i !== defKeys.length; i += 1) {
    const key = defKeys[i]
    const def = nodeDefinitions[key]
    const { id } = def
    const node = productNodes[id]
    if (node) {
      node.score = node.score && !Number.isNaN(Number(node.score)) ? parseFloat(node.score) : node.score
      node.maxScore = node.maxScore && !Number.isNaN(Number(node.maxScore)) ? parseFloat(node.maxScore) : node.maxScore
      const { score, maxScore } = node
      node.normalizedScore = maxScore > 0 ? (score * configMaxScore) / maxScore : 0
      node.normalizedScore = node.normalizedScore > configMaxScore ? configMaxScore : node.normalizedScore
      const isNumber = !Number.isNaN(Number(node.normalizedScore))
      if (!isNumber) {
        console.log('node.normalizedScore', node.normalizedScore)
        console.log('score', score)
        console.log('maxScore', maxScore)
        console.log('configMaxScore', configMaxScore)
        console.log('node', node)
      }
    }
  }
}

export function doCreateScorePanel(scoring, nodes, defs) {
  const nodeDefinitionsKeys = Object.keys(defs)
  const treeMap = global.env.treeMap.split(',')
  const scorePanel = []
  let nodeDefKey = ''
  const firstLeveId = nodeDefinitionsKeys[0]
  for (let i = 0; i < nodeDefinitionsKeys.length; i += 1) {
    nodeDefKey = nodeDefinitionsKeys[i]
    if (defs[nodeDefKey].type === treeMap[1] && defs[nodeDefKey].parent_id === firstLeveId) {
      scorePanel.push({ name: defs[nodeDefKey].name.en, values: [] })
    }
  }
  let firstLevelName = {}
  for (let i = 0; i < nodeDefinitionsKeys.length; i += 1) {
    nodeDefKey = nodeDefinitionsKeys[i]
    if (defs[nodeDefKey].type === treeMap[0]) {
      firstLevelName = defs[nodeDefKey].name
    }
    if (defs[nodeDefKey].type === treeMap[1]) {
      for (let j = 0; j < scorePanel.length; j += 1) {
        if (scorePanel[j].name === defs[nodeDefKey].name.en) {
          if (typeof nodes[nodeDefKey] !== 'undefined') {
            scorePanel[j].values.push({
              name: firstLevelName,
              value: nodes[nodeDefKey].normalizedScore,
              emotional_value: nodes[nodeDefKey].normalizedEmotionalScore,
              is_enabled: nodes[nodeDefKey].is_enabled,
            })
          }
        }
      }
    }
  }

  // Digital values
  const digitalItem = _.find(defs, item => item.name.en.toLowerCase() === 'initial impression of screens and displays')
  if (digitalItem) {
    const digitalItemId = digitalItem.id

    scorePanel.push({
      // We want "digital" scores at the last position in the array
      name: 'Digital',
      values: [
        {
          is_enabled: true,
          name: scorePanel[0].values[0].name,
          value: digitalItemId && nodes[digitalItemId] && nodes[digitalItemId].normalizedScore,
        },
        {
          is_enabled: true,
          name: scorePanel[0].values[1].name,
          value: scoring.score_digital,
        },
      ],
    })
    // End digital values
  }

  return scorePanel
}

export async function doCalculation(scoring, nodeDefinitions, productNodes, skipScoringPanel) {
  const { id: scoringId } = scoring
  let treeMap = global.env.treeMap.split(',')

  // Perimeter score got calculated for charts, not used in scoringtree view
  // treeMap.shift() // Remove Perimeter because calc arrive only on families

  treeMap.pop() // Remove criteria
  treeMap = treeMap.reverse() // Reverse array so now i have [item, subfamily, family, perimeter]

  const criteria = []
  const demerits = []
  const defKeys = Object.keys(nodeDefinitions)

  // Get Criteria and Demerits
  for (let i = 0; i !== defKeys.length; i += 1) {
    const key = defKeys[i]
    const def = nodeDefinitions[key]
    const { id } = def
    const node = productNodes[id]
    if (node) {
      if (def.type === nodeDefinitionTypeMap.criterion && node.is_enabled === true) {
        if (def.bonus_demerit === false) {
          criteria.push(def)
        } else {
          demerits.push(def)
        }
      }
    }
  }

  // Empty all scores but criteria
  const pNodeKeys = Object.keys(productNodes)
  for (let i = 0; i !== pNodeKeys.length; i += 1) {
    const nodeDefinitionId = pNodeKeys[i]
    const node = productNodes[nodeDefinitionId]
    const { type } = nodeDefinitions[node.node_definition_id]
    if ([nodeDefinitionTypeMap.criterion].indexOf(type) === -1) {
      node.score = 0
      node.maxScore = 0
      node.percentage = 0
      node.normalizedScore = 0
      node.scored = 0
      node.notScored = 0
      node.percentageScored = 0
      node.normalizedScoreContribution = 0
      node.normalizedMaxScoreContribution = 0
      node.normalizedEmotionalScore = 0
      if (type !== 'item') {
        node.itemsCount = 0
      }
    }
  }

  let scoreDigital = 0
  let scoreDigitalMax = 0

  // STEP 1
  // -----------------------------------------------------------------------------------------
  // For each Criterion multiply score*weigth then for parents weight
  // on treeMap from criteria to perimeters
  for (let c = 0; c < criteria.length; c += 1) {
    const criterion = criteria[c]
    // get scoring node
    const node = productNodes[criterion.id]
    productNodes[criterion.id].maxScore = global.env.config.max_score
    productNodes[criterion.id].percentage =
      (productNodes[criterion.id].score * 100) / productNodes[criterion.id].maxScore

    // Start calc on criterion
    let calc = node.score * nodeDefinitions[node.node_definition_id].weight
    let maxCalc = global.env.config.max_score * nodeDefinitions[node.node_definition_id].weight

    let fatherNodeDefinitionId = criterion.parent_id

    // For each criterion's parent
    // This has been changed to include perimeter...
    // We have now treeMap.length = 4 (item, subfamily, family, perimeter)
    for (let t = 0; t < treeMap.length; t += 1) {
      // Get template father
      const father = nodeDefinitions[fatherNodeDefinitionId]

      // Do calc
      calc *= father.weight
      maxCalc *= father.weight

      if (!node.is_enabled || node.is_default) {
        productNodes[father.id].notScored += 1
      } else {
        productNodes[father.id].scored += 1
      }

      // Get this node father id
      fatherNodeDefinitionId = father.parent_id
    }

    // Restart from Item
    fatherNodeDefinitionId = criterion.parent_id

    if (itemIsDigital(nodeDefinitions, fatherNodeDefinitionId)) {
      scoreDigital += calc
      scoreDigitalMax += maxCalc
    }

    // For each criterion's parent sum calc
    // Also here we loop 4 levels (item, subfamily, family, perimeter)
    for (let t = 0; t < treeMap.length; t += 1) {
      // Sum calc on this node's score
      productNodes[fatherNodeDefinitionId].score += calc
      productNodes[fatherNodeDefinitionId].maxScore += maxCalc
      productNodes[fatherNodeDefinitionId].percentage =
        (productNodes[fatherNodeDefinitionId].score * 100) / productNodes[fatherNodeDefinitionId].maxScore

      // Keep this. For now Perimeter has score
      // if (maxScores[treeMap[t]] < productNodes[fatherNodeDefinitionId].score) {
      // maxScores[treeMap[t]] = productNodes[fatherNodeDefinitionId].score
      // }

      // Get this node father id
      fatherNodeDefinitionId = nodeDefinitions[fatherNodeDefinitionId].parent_id
      // In case of perimeter the fatherNodeDefinitionId is null
      // We do not set anything at father itemsCount for perimeter
      if (fatherNodeDefinitionId) {
        productNodes[fatherNodeDefinitionId].itemsCount += 1
      }
    }
  }

  // Only for Renault store SCORE_DIGITAL into SCORING
  const configMaxScore = JSON.parse(global.env.config.max_product_score)
  scoreDigital = (scoreDigital * configMaxScore) / scoreDigitalMax
  scoreDigital = scoreDigital > configMaxScore ? configMaxScore : scoreDigital
  scoreDigital = toFixed1IfDecimal(scoreDigital)
  const extraData = {}
  extraData.id = `scoring_${scoringId}_score_digital`
  extraData.data = scoreDigital
  await storage.update(storageMap.extra_data, extraData)
  scoring.score_digital = scoreDigital

  // STEP 1.2 GUESS DEMERITS SCORED / UNSCORED
  for (let d = 0; d < demerits.length; d += 1) {
    const demerit = demerits[d]
    const node = productNodes[demerit.id]
    let fatherNodeDefinitionId = demerit.parent_id
    for (let t = 0; t < treeMap.length + 1; t += 1) {
      const father = nodeDefinitions[fatherNodeDefinitionId]
      if (father) {
        if (!node.is_enabled || node.is_default) {
          productNodes[father.id].notScored += 1
        } else {
          productNodes[father.id].scored += 1
        }
        fatherNodeDefinitionId = father.parent_id
      }
    }
  }

  // STEP 2
  // -----------------------------------------------------------------------------------------
  // Get levels maxScores per perimeter
  // This will contain max score of every level distinct for every perimeter
  treeMap = treeMap.reverse() // Reverse array so now i have [family, subfamily, item]
  const maxScores = {}
  for (let i = 0; i !== defKeys.length; i += 1) {
    const def = nodeDefinitions[defKeys[i]]
    if (def.type === 'perimeter') {
      maxScores[def.id] = {}
      findMaxs(0, treeMap, def.id, def.id, maxScores, defKeys, nodeDefinitions, productNodes)
    }
  }

  // STEP 3
  // -----------------------------------------------------------------------------------------
  // Calculate Normalized scores
  setNormalizedScores(defKeys, nodeDefinitions, productNodes)

  // STEP 4
  // -----------------------------------------------------------------------------------------
  // Calculate family emotional potential
  for (let d = 0; d < demerits.length; d += 1) {
    const demerit = demerits[d]

    let fatherNodeDefinitionId = demerit.id
    for (let t = 1; t < treeMap.length; t += 1) {
      fatherNodeDefinitionId = nodeDefinitions[fatherNodeDefinitionId].parent_id
      productNodes[fatherNodeDefinitionId].itemsCount += 1
    }

    // This was old bonus/demerit calc
    // famScore += (productNodes[demerit.id].score * family.score * global.env.config.demerit_behavior.value) / 100
    // VAV3-1165 Emotional potential: is like bonus/demerit but every point in criterion worths 18 points in
    // the family normalized score
    let famEmotionalScore = productNodes[fatherNodeDefinitionId].normalizedEmotionalScore
    famEmotionalScore += productNodes[demerit.id].score * 18
    productNodes[fatherNodeDefinitionId].normalizedEmotionalScore = famEmotionalScore
    productNodes[fatherNodeDefinitionId].normalizedScore += famEmotionalScore
  }

  // STEP 4.1
  // -----------------------------------------------------------------------------------------
  // Update perimeters score after the families bonus/demerit tweak has been applied
  const perimeterNodeDefinitions = _.filter(nodeDefinitions, item => {
    return item.parent_id === null
  })
  perimeterNodeDefinitions.forEach(perimeterNodeDefinition => {
    let emotionalScore = 0
    const childrenIds = perimeterNodeDefinition.children_ids
    childrenIds.forEach(childId => {
      const family = productNodes[childId]
      emotionalScore += family.normalizedEmotionalScore
    })
    productNodes[perimeterNodeDefinition.id].normalizedScore += emotionalScore
  })

  // STEP 5
  // -----------------------------------------------------------------------------------------
  // For every node but criterion calc scored percentage
  for (let i = 0; i !== defKeys.length; i += 1) {
    const def = nodeDefinitions[defKeys[i]]
    if (def.type !== nodeDefinitionTypeMap.criterion) {
      const node = productNodes[def.id]
      if (node) {
        const { scored, notScored } = node
        const tot = scored + notScored
        if (tot > 0) {
          productNodes[def.id].percentageScored = (scored / tot) * 100
        }
      }
    }
  }

  // STEP 6
  // -----------------------------------------------------------------------------------------
  // For each criteria, calc score contribution
  const calcFullWeight = (nodDef, weight) => {
    if (nodDef.type !== 'perimeter') {
      // Until family included
      const parentNodeDef = nodeDefinitions[nodDef.parent_id]
      weight = calcFullWeight(parentNodeDef, weight * nodDef.weight)
    }

    return weight
  }

  criteria.forEach(criterion => {
    const node = productNodes[criterion.id]
    const family = Object.values(nodeDefinitions).find(
      _nodeDef => _nodeDef.type === 'family' && _nodeDef._left < criterion._left && _nodeDef._right > criterion._right
    )

    const criteriaInFamily = Object.values(nodeDefinitions).filter(
      _criterion =>
        _criterion.type === 'criterion' &&
        _criterion._left > family._left &&
        _criterion._right < family._right &&
        productNodes[_criterion.id].is_enabled
    )

    if (criteriaInFamily.length === 0) return

    const weight = calcFullWeight(criterion, 1)
    const familyCriteriaWeight = criteriaInFamily
      .map(_criterion => calcFullWeight(_criterion, 1))
      .reduce((_sum, _current) => _sum + _current, 0)

    const maxScoreContribution = weight / familyCriteriaWeight
    const scoreContribution = (maxScoreContribution / node.maxScore) * node.score

    productNodes[criterion.id].normalizedMaxScoreContribution = maxScoreContribution * configMaxScore
    productNodes[criterion.id].normalizedScoreContribution = scoreContribution * configMaxScore
  })

  if (skipScoringPanel) {
    return productNodes
  }

  // STEP 7
  // -----------------------------------------------------------------------------------------
  // Update offline_scorings_list to view score panel updated on search
  const scorePanel = doCreateScorePanel(scoring, productNodes, nodeDefinitions)
  const offlineScoring = await storage.get(storageMap.offline_scorings_list, scoringId)
  if (offlineScoring) {
    offlineScoring._source.score_panel = scorePanel
    await storage.update(storageMap.offline_scorings_list, offlineScoring)
  }
  return productNodes
}

export async function doCalculationForAnalytics(nodeDefinitions, productNodes) {
  let treeMap = process.env.treeMap.split(',')

  treeMap.pop() // Remove criteria
  treeMap = treeMap.reverse() // Reverse array so now i have [item, subfamily, family, perimeter]

  const criteria = []
  const demerits = []
  const defKeys = Object.keys(nodeDefinitions)

  // Get Criteria and Demerits
  for (let i = 0; i !== defKeys.length; i += 1) {
    const key = defKeys[i]
    const def = nodeDefinitions[key]
    const { id } = def
    const node = productNodes[id]
    if (node) {
      if (def.type === nodeDefinitionTypeMap.criterion && node.is_enabled === true) {
        if (def.bonus_demerit === false) {
          criteria.push(def)
        } else {
          demerits.push(def)
        }
      }
    }
  }

  // Empty all scores but criteria
  const pNodeKeys = Object.keys(productNodes)
  for (let i = 0; i !== pNodeKeys.length; i += 1) {
    const nodeDefinitionId = pNodeKeys[i]
    const node = productNodes[nodeDefinitionId]
    const { type } = nodeDefinitions[node.node_definition_id]
    if ([nodeDefinitionTypeMap.criterion].indexOf(type) === -1) {
      node.score = 0
      node.maxScore = 0
      node.percentage = 0
      node.normalizedScore = 0
      node.scored = 0
      node.notScored = 0
      node.percentageScored = 0
      node.normalizedScoreContribution = 0
      node.normalizedMaxScoreContribution = 0
      node.normalizedEmotionalScore = 0
      if (type !== 'item') {
        node.itemsCount = 0
      }
    }
  }

  // STEP 1
  // -----------------------------------------------------------------------------------------
  // For each Criterion multiply score*weigth then for parents weight
  // on treeMap from criteria to perimeters
  for (let c = 0; c < criteria.length; c += 1) {
    const criterion = criteria[c]
    // get scoring node
    const node = productNodes[criterion.id]
    productNodes[criterion.id].maxScore = global.env.config.max_score
    productNodes[criterion.id].percentage =
      (productNodes[criterion.id].score * 100) / productNodes[criterion.id].maxScore

    // Start calc on criterion
    let calc = node.score * nodeDefinitions[node.node_definition_id].weight
    let maxCalc = global.env.config.max_score * nodeDefinitions[node.node_definition_id].weight

    let fatherNodeDefinitionId = criterion.parent_id

    // For each criterion's parent
    // This has been changed to include perimeter...
    // We have now treeMap.length = 4 (item, subfamily, family, perimeter)
    for (let t = 0; t < treeMap.length; t += 1) {
      // Get template father
      const father = nodeDefinitions[fatherNodeDefinitionId]

      // Do calc
      calc *= father.weight
      maxCalc *= father.weight

      if (!node.is_enabled || node.is_default) {
        productNodes[father.id].notScored += 1
      } else {
        productNodes[father.id].scored += 1
      }

      // Get this node father id
      fatherNodeDefinitionId = father.parent_id
    }

    // Restart from Item
    fatherNodeDefinitionId = criterion.parent_id

    // For each criterion's parent sum calc
    // Also here we loop 4 levels (item, subfamily, family, perimeter)
    for (let t = 0; t < treeMap.length; t += 1) {
      // Sum calc on this node's score
      productNodes[fatherNodeDefinitionId].score += calc
      productNodes[fatherNodeDefinitionId].maxScore += maxCalc
      productNodes[fatherNodeDefinitionId].percentage =
        (productNodes[fatherNodeDefinitionId].score * 100) / productNodes[fatherNodeDefinitionId].maxScore

      // Get this node father id
      fatherNodeDefinitionId = nodeDefinitions[fatherNodeDefinitionId].parent_id
      // In case of perimeter the fatherNodeDefinitionId is null
      // We do not set anything at father itemsCount for perimeter
      if (fatherNodeDefinitionId) {
        productNodes[fatherNodeDefinitionId].itemsCount += 1
      }
    }
  }

  // STEP 1.2 GUESS DEMERITS SCORED / UNSCORED
  for (let d = 0; d < demerits.length; d += 1) {
    const demerit = demerits[d]
    const node = productNodes[demerit.id]
    let fatherNodeDefinitionId = demerit.parent_id
    for (let t = 0; t < treeMap.length + 1; t += 1) {
      const father = nodeDefinitions[fatherNodeDefinitionId]
      if (father) {
        if (!node.is_enabled || node.is_default) {
          productNodes[father.id].notScored += 1
        } else {
          productNodes[father.id].scored += 1
        }
        fatherNodeDefinitionId = father.parent_id
      }
    }
  }

  // STEP 2
  // -----------------------------------------------------------------------------------------
  // Get levels maxScores per perimeter
  // This will contain max score of every level distinct for every perimeter
  treeMap = treeMap.reverse() // Reverse array so now i have [perimeter, family, subfamily, item]
  const maxScores = {}
  for (let i = 0; i !== defKeys.length; i += 1) {
    const def = nodeDefinitions[defKeys[i]]
    if (def.type === 'perimeter') {
      maxScores[def.id] = {}
      findMaxs(0, treeMap, def.id, def.id, maxScores, defKeys, nodeDefinitions, productNodes)
    }
  }

  // STEP 3
  // -----------------------------------------------------------------------------------------
  // Calculate Normalized scores
  setNormalizedScores(defKeys, nodeDefinitions, productNodes)

  // STEP 4
  // -----------------------------------------------------------------------------------------
  // Calculate family emotional potential
  for (let d = 0; d < demerits.length; d += 1) {
    const demerit = demerits[d]

    let fatherNodeDefinitionId = demerit.id
    for (let t = 1; t < treeMap.length; t += 1) {
      fatherNodeDefinitionId = nodeDefinitions[fatherNodeDefinitionId].parent_id
      productNodes[fatherNodeDefinitionId].itemsCount += 1
    }

    // This was old bonus/demerit calc
    // famScore += (productNodes[demerit.id].score * family.score * global.env.config.demerit_behavior.value) / 100
    // VAV3-1165 Emotional potential: is like bonus/demerit but every point in criterion worths 18 points in
    // the family normalized score
    let famEmotionalScore = productNodes[fatherNodeDefinitionId].normalizedEmotionalScore
    famEmotionalScore += productNodes[demerit.id].score * 18
    productNodes[fatherNodeDefinitionId].normalizedEmotionalScore = famEmotionalScore
    productNodes[fatherNodeDefinitionId].normalizedScore += famEmotionalScore
  }

  // STEP 4.1
  // -----------------------------------------------------------------------------------------
  // Update perimeters score after the families bonus/demerit tweak has been applied
  const perimeterNodeDefinitions = _.filter(nodeDefinitions, item => {
    return item.parent_id === null
  })
  perimeterNodeDefinitions.forEach(perimeterNodeDefinition => {
    let emotionalScore = 0
    const childrenIds = perimeterNodeDefinition.children_ids
    childrenIds.forEach(childId => {
      const family = productNodes[childId]
      emotionalScore += family.normalizedEmotionalScore
    })
    productNodes[perimeterNodeDefinition.id].normalizedScore += emotionalScore
  })

  // STEP 5
  // -----------------------------------------------------------------------------------------
  // For every node but criterion calc scored percentage
  for (let i = 0; i !== defKeys.length; i += 1) {
    const def = nodeDefinitions[defKeys[i]]
    if (def.type !== nodeDefinitionTypeMap.criterion) {
      const node = productNodes[def.id]
      if (node) {
        const { scored, notScored } = node
        const tot = scored + notScored
        if (tot > 0) {
          productNodes[def.id].percentageScored = (scored / tot) * 100
        }
      }
    }
  }

  return productNodes
}

export const rolesInfo = rolesInfoData
